<template>
	<div class="cont" id="p13">
		<div class="page-title"> {{ program.name }} </div>

		<div class="c01">
			<div class="s01">
				<div class="s-item">
					<div class="s-header">
						<div class="s-title">신청정보</div>
					</div>
					<div class="s-body">
						<div class="s-content">
							<div class="si-title">사업자 구분</div>
							<div class="si-desc text-right">{{ item.companyTypeName }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">아이디</div>
							<div class="si-desc text-right"> {{ item.isAutoUserId ? '자동발급' : '입력'}} ({{ item.userId }}) </div>
						</div>
						<div class="s-content">
							<div class="si-title">신청 상태</div>
							<div class="si-desc text-right"> {{ itemStatus.serviceStatusName }} </div>
						</div>
					</div>
				</div>
				<div class="s-item t-item">
					<div class="s-header">
						<div class="s-title">이용 서비스</div>
					</div>
					<div class="s-body">
						<div class="table type03">
							<div class="item-header">
								<div class="item">
									<div class="td">서비스</div>
									<div class="td">공급가</div>
									<div class="td">마진</div>
									<div class="td">최종 수수료</div>
								</div>
							</div>
							<div class="item-list">
								<div class="item"
									v-for="(item2, index) in item.payChnCate"
									:key="index"
								>
									<div class="td">
										<div class="cont-check">
											<input type="checkbox" id="" 
												:checked="true"
											>
											<label for="ck01"><span>{{ item2 == '001' ? '인증(온라인)' : ( item2 == '002' ? '비인증(수기)' : 'OFF PG(단말기)' )}}</span></label>
										</div>
									</div>
									<div class="td">{{ user['paymentItems'][0] ? user['paymentItems'][0].feeRate : 0 | makeComma }}%</div>
									<div class="td">
										{{ item.feeRate[index] - (user['paymentItems'][0] ? user['paymentItems'][0].feeRate : 0) }} %
									</div>
									<div class="td">{{ item.feeRate[index] }} %</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="s-item">
					<div class="s-header">
						<div class="s-title">보증보험</div>
						<span class="sp" style="font-size: 0.8em; color: red;">1년 단위 갱신 계약</span>
					</div>
					<div class="s-body">
						<div class="s-content">
							<div class="si-title">보증보험</div>
							<div class="si-desc text-right">{{ item.insuranceCoverageAmount | makeComma }}원</div>
						</div>
						<div class="s-content">
							<div class="si-title">건별 한도</div>
							<div class="si-desc text-right">{{ item.oneTimeLimit | makeComma  }}원</div>
						</div>
						<div class="s-content">
							<div class="si-title">월 한도</div>
							<div class="si-desc text-right">{{ item.monthLimit | makeComma  }}원</div>
						</div>
						<div class="s-content">
							<div class="si-title">년 한도</div>
							<div class="si-desc text-right">{{ item.yearLimit | makeComma  }}원</div>
						</div>
					</div>
				</div>
				
				<div class="s-item">
					<div class="s-header">
						<div class="s-title">가맹점 필수 정보</div>
					</div>
					<div class="s-body">
						<div class="s-content">
							<div class="si-title">상호</div>
							<div class="si-desc">{{ item.businessName }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">사업자등록번호</div>
							<div class="si-desc">{{ item.businessNumber }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">대표번호</div>
							<div class="si-desc">{{ item.phoneNumber | makePhoneNumber }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">이메일</div>
							<div class="si-desc">{{ item.companyEmail }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">사업장 주소</div>
							<div class="si-desc">
								{{ item.zipCode }} 
								{{ item.addressMain }} 
							</div>
						</div>
						<div class="s-content">
							<div class="si-title">상세 주소</div>
							<div class="si-desc">
								{{ item.addressDetail }}
							</div>
						</div>
					</div>
				</div>
				
				
				<div class="s-item" 
					v-if="item.companyType != 'NON'"
				>
					<div class="s-header">
						<div class="s-title">가맹점 부가 정보</div>
					</div>
					<div class="s-body">
						<div class="s-content">
							<div class="si-title">법인 등록번호</div>
							<div class="si-desc">{{ item.corporateNumber }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">팩스</div>
							<div class="si-desc">{{ item.faxNumber }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">업태</div>
							<div class="si-desc">{{ item.businessType}}</div>
						</div>
						<div class="s-content">
							<div class="si-title">업종</div>
							<div class="si-desc">{{ item.businessCate }}</div>
						</div>
					</div>
				</div>
				
				<div class="s-item">
					<div class="s-header">
						<div class="s-title">대표자 정보</div>
					</div>
					<div class="s-body">
						<div class="s-content">
							<div class="si-title">대표자 성명</div>
							<div class="si-desc">{{ item.ceoName }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">대표자 연락처</div>
							<div class="si-desc">{{ item.ceoMobilPhoneNumber | makePhoneNumber }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">대표자 생년월일</div>
							<div class="si-desc">{{ item.ceoBirth }}</div>
						</div>
						<div class="s-content input">
							<div class="si-title">관리 담당자</div>
							<div class="si-desc">{{ item.managerName }}</div>
						</div>
						<div class="s-content input">
							<div class="si-title">담당자 연락처</div>
							<div class="si-desc">
							<div class="si-desc">{{ item.managerMobilPhoneNumber | makePhoneNumber }}</div>
							</div>
						</div>
						<div class="s-content input">
							<div class="si-title">담당자 이메일</div>
							<div class="si-desc">
							<div class="si-desc">{{ item.managerEmail }}</div>
							</div>
						</div>
					</div>
				</div>
				
				<div class="s-item">
					<div class="s-header">
						<div class="s-title">대금 정산</div>
					</div>
					<div class="s-body">
						<div class="s-content">
							<div class="si-title">정산주기</div>
							<div class="si-desc">D+{{ item.settlementCycle }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">정산은행</div>
							<div class="si-desc">{{ item.bankCode }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">계좌번호</div>
							<div class="si-desc">{{ item.bankAccountNumber }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">예금주</div>
							<div class="si-desc">{{ item.bankAccountHolder }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">세금계산서 <br/> 받을 이메일</div>
							<div class="si-desc">{{ item.billEmail }}</div>
						</div>
					</div>
				</div>
				
				<div class="s-item none">
					<div class="s-header">
						<div class="s-title">담당자</div>
					</div>
					<div class="s-body">
						<div class="s-content">
							<div class="si-title">담당자</div>
							<div class="si-desc">
								<input type="text" placeholder="담당자 이름을 입력하세요"
									v-model="item.managerName"
								>
								
							</div>
						</div>
						<div class="s-content">
							<div class="si-title">핸드폰</div>
							<div class="si-desc">
								<input type="text" placeholder="담당자 연락처를 입력하세요"
									v-model="item.managerMobilPhoneNumber"
								>
							</div>
						</div>
						<div class="s-content">
							<div class="si-title">이메일</div>
							<div class="si-desc">
								<input type="text" placeholder="담당자 이메일을 입력하세요"
									v-model="item.managerMobilPhoneNumber"
								>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="s02 none">
				<div class="s-header">
					<div class="s-title">이용 서비스</div>
				</div>
				<div class="s-body">
					<div class="table type03">
						<div class="item-header">
							<div class="item">
								<div class="td">서비스</div>
								<div class="td">공급가</div>
								<div class="td">대리점 마진</div>
								<div class="td">리셀러 마진</div>
								<div class="td">딜러 마진</div>
								<div class="td">최종 수수료</div>
							</div>
						</div>
						<div class="item-list">
							<div class="item">
								<div class="td">
									<div class="cont-check">
										<input type="checkbox" id="ck01">
										<label for="ck01"><span>인증(온라인)</span></label>
									</div>
								</div>
								<div class="td">2.5%</div>
								<div class="td">
									<input type="text" placeholder="2%">
								</div>
								<div class="td">2%</div>
								<div class="td">2%</div>
								<div class="td">6.5%</div>
							</div>

							<div class="item">
								<div class="td">
									<div class="cont-check">
										<input type="checkbox" id="ck02">
										<label for="ck02"><span>비인증(수기)</span></label>
									</div>
								</div>
								<div class="td">3%</div>
								<div class="td">
									<input type="text" placeholder="2%">
								</div>
								<div class="td">2%</div>
								<div class="td">2%</div>
								<div class="td">7%</div>
							</div>

							<div class="item">
								<div class="td">
									<div class="cont-check">
										<input type="checkbox" id="ck03">
										<label for="ck03"><span>OFF PG(단말기)</span></label>
									</div>
								</div>
								<div class="td">4%</div>
								<div class="td">
									<input class="none" type="text" placeholder="2%"> <!-- 숨김 시 class "none" -->
								</div>
								<div class="td"></div>
								<div class="td"></div>
								<div class="td">4%</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="s03 none">
				<div class="s-item_list">
					<div class="s-item">
						<div class="s-header">
							<div class="s-title">가맹점 정보</div>
						</div>
						<div class="s-body">
							<div class="s-content">
								<div class="si-title">대표자 성명</div>
								<div class="si-desc">{{ item.ceoName }}</div>
							</div>
							<div class="s-content">
								<div class="si-title">대표자 연락처</div>
								<div class="si-desc">{{ item.ceoMobilPhoneNumber }}</div>
							</div>
							<div class="s-content">
								<div class="si-title">대표 전화</div>
								<div class="si-desc">{{ item.ceoName }}</div>
							</div>
						</div>
					</div>
					<div class="s-item">
						<div class="s-header">
							<div class="s-title" style="visibility: hidden;">헤더</div>
						</div>
						<div class="s-body">
							<div class="s-content">
								<div class="si-title">사업자</div>
								<div class="si-desc">{{ item.businessName }}</div>
							</div>
							<div class="s-content">
								<div class="si-title">상호명(법인명)</div>
								<div class="si-desc">{{ item.businessName }}</div>
							</div>
							<div class="s-content">
								<div class="si-title">사업자등록번호</div>
								<div class="si-desc">{{ item.businessName }}</div>
							</div>
							<div class="s-content">
								<div class="si-title">업태/업종</div>
								<div class="si-desc">{{ item.businessName }} / {{ item.businessName }}</div>
							</div>
							<div class="s-content">
								<div class="si-title">서비스 구분</div>
								<div class="si-desc">{{ item.servicesType }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="s04">
				<a @click="$router.go(-1)"><div class="btn-back">목록</div></a>
				<router-link 
					v-if="itemStatus.serviceStatus == 'Rejected'"
					:to="{ name: 'MerchantsApplyItem', params: { index: item.id }}"><div class="btn-save">재심사 요청</div></router-link>
			</div>
		</div>
		
		<FranchiseePopupPassword 
			v-if="showPopup.password"
			:item="item"
			
			@setOverlay="setOverlay"
			@setNotify="setNotify"
		/>
		
		<FranchiseePopupAccount
			v-if="showPopup.account"
			:item="itemStatus"

			@setOverlay="setOverlay"
			@setNotify="setNotify"
		/>
	</div>
</template>

<script>
	import { Axios } from '@/resources/axios/axios'
	import { filter } from '@/resources/filter/filter'
	
	import FranchiseePopupPassword from '@/view/Franchisee/FranchiseePopupPassword'
	import FranchiseePopupAccount from '@/view/Franchisee/FranchiseePopupAccount'
	
	export default{
		name: "MerchantsApplyDetail"
		,props: ['user']
		,components: { FranchiseePopupPassword, FranchiseePopupAccount }
		,data: function(){
			return {
				program: {
					name: '가맹점 상세 정보'
				}
				,item: {
					payChnCate: ['', '', '']
				}
				,itemStatus: {
				}
				,id: this.$route.params.index
				,showPopup: {
					password: false
					,account: false
				}
			}
		}
		,filter: filter
		,methods: {
			save: async function(){
				const self = this
				try{
					const result = await Axios({
						method: 'get'
						,url: '/merchants/apply/' + self.id + '/update'
						,data: this.item
						,authorize: true
					})
					
					if(result.success){
						this.$emit('setNotify', { type: 'success', message: '정상적으로 처리되었습니다'})
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message})
					}
				}catch(E){
					this.$emit('setNotify', { type: 'error', message: E})
				}
			}
			,getData: async function(){
				const self = this
				try{
					const result = await Axios({
						method: 'get'
						,url: '/merchants/apply/' + self.id + '/update'
						,authorize: true
					})
					
					if(result.success){
						this.item = result.data.content
						if(!this.item.payChnCate){
							this.item.payChnCate = ['', '', '']
						}
						await this.getStatus()
					}else{
						this.$router.push({ name: 'MerchantsApplyList'})
						this.$emit('setNotify', { type: 'error', message: result.message })
					}
				}catch(E){
					console.log(E)
					this.$router.push({ to: 'MerchantsApplyList'})
					this.$emit('setNotify', { type: 'error', message: E })
				}
			}
			,getStatus: async function(){
				const self = this
				try{
					const result = await Axios({
						method: 'get'
						,url: '/merchants/apply/' + self.id
						,authorize: true
					})
					
					if(result.success){
						this.itemStatus = result.data.content
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message })
					}
				}catch(E){
					console.log(E)
					this.$router.push({ to: 'FranchiseeList'})
					this.$emit('setNotify', { type: 'error', message: E })
				}
			}
			,viewPassword: function(){
				this.showPopup.password = true
				this.$emit('setOverlay', true)
			}
			
			,viewAccount: function(){
				this.showPopup.account = true
				this.$emit('setOverlay', true)
			}
			,setOverlay: function(type){
				this.$emit('setOverlay', type)
				if(!type){
					this.showPopup.password = false
					this.showPopup.account = false
				}
			}
			,setNotify: function(option){
				this.$emit('setNotify', option)
			}
		}
		,created: function(){
			this.$emit('setProgram', this.program)
			this.getData()
		}
	}
</script>

<style>
	.table.type03 .td:not(:first-child) {width: 25% !important; justify-content: center !important; padding-right: 0 !important;}
	.s-title {width: 150px !important;}
</style>






